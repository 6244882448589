const routes = [
  {
    index: true,
    label: "Naushad Ali",
    path: "/",
  },
  // {
  //   label: "About", // TODO Uncomment it to enable about
  //   path: "/about",
  // },
  {
    label: "Resume",
    path: "/resume",
  },
  {
    label: "Projects",
    path: "/projects",
  },
  {
    label: "Stats",
    path: "/stats",
  },
  {
    label: "Contact",
    path: "/contact",
  },
];

export default routes;
